<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  enrolMethods
} from "@/state/helpers";

/**
 * Groups component
 */
export default {
  page: {
    title: "Gerenciar Alunos",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      tableData: [],
      title: "Gerenciar Alunos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Alunos",
          active: true,
        },
      ],
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        image: "Foto",
        name: "Nome",
        email: "Email",
        courses: "Cursos Inscritos",
        actions: "Ações",
      },
      selectedStudentName: [],
      selectedCourses: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    fields() {
      return [
        { key: "id", sortable: true, label: this.fieldAliases.id, thStyle: { width: "8%" } },
        { key: "image", sortable: true, label: this.fieldAliases.image, thStyle: { width: "8%" } },
        { key: "name", sortable: true, label: this.fieldAliases.name },
        { key: "email", sortable: true, label: this.fieldAliases.email },
        { key: "courses", sortable: true, label: this.fieldAliases.courses, thStyle: { width: "12%" } },
        { key: "actions", sortable: true, label: this.fieldAliases.actions, thStyle: { width: "110px" } }
      ];
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  created() {
    this.loadStudents();
  },
  methods: {
    ...enrolMethods,

    async loadStudents() {
      const students = await this.getAll();
      this.tableData = students || [];
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    showCourses(student) {
      this.selectedStudentName = student.name;
      this.selectedCourses = student.courses;
      this.showModal = true;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Alunos</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- Coluna "image" personalizada -->
                <template #cell(image)="student">
                  <div class="actions-cell">
                    <b-img
                      rounded='circle'
                      :src="require('@/assets/images/small/img-3.jpg')"
                      :name="student.item.image"
                      width="70"
                      height="70"
                      alt="70x70"
                    ></b-img>
                  </div>
                </template>
                <template #cell(courses)="student">
                  <div class="actions-cell">
                    <b-button class="w-100" pill @click="showCourses(student.item)" variant="outline-primary">
                      Ver Cursos
                    </b-button>
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="group">
                  <div class="actions-cell">
                    <b-button class="w-100" pill @click="delete(group.item)" variant="outline-primary">
                      <i class="mdi mdi-dots-vertical"></i>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showModal" id="modal-dialog" :title="'Cursos do Aluno: ' + selectedStudentName" title-class="font-18" hide-footer>
      <ul>
        <li v-for="course in selectedCourses" :key="course.id">{{ course.name }}</li>
      </ul>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
